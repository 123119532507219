.services{
    padding: 60px 100px;
    width: 100%-200px;
}

.services h1{
    line-height: 1.25em;
    text-decoration: underline #282828;
    margin-bottom: 24px;
}

.services p{
    line-height: 1.4em;
}

.services-all{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.services-column{
    width: 47%;
}

.service-one{
    display: flex;
    padding: 28px 0px;
    align-items: center;
}

.service-one img{
    width: 120px;
    height: 120px;
    margin-right: 32px;
}

@media only screen and (max-width: 980px){
    .services-all{
        flex-direction: column;
    }
    .services p{
        line-height: 1.2em;
    }

    .service-one img{
        width: 100px;
        height: 100px;
        margin-right: 24px;
    }

    .services-column{
        width: 100%;
    }
}

@media only screen and (max-width: 798px){
    .services{
        padding: 30px 60px;
        width: 100%-120px;
        text-align: center;
        justify-content: center;
    }

    .services-all{
        flex-direction: column;
    }

    .services-column{
        text-align: left;
    }
}