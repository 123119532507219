@import url('https://fonts.googleapis.com/css2?family=Patua+One&family=Roboto+Slab:wght@380&display=swap');

body{
    padding: 50px 30px;
    margin: 0px;
    font-family: 'Roboto Slab', 'Lucida Sans', sans-serif;
    color: #282828;
}

.lang-ang{
    display: none;
}

@media only screen and (max-width: 796px){
    body{
        padding: 30px 15px;
    }
}