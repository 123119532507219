.footer{
    padding: 10px 100px;
    width: 100%-200px;
    background-color: #282828;
    color: white;
    text-align: center;
}

.footer p{
    margin:0px;
}

.footer p:first-child{
    font-size: 0.7em;
    margin-bottom: 8px;
}

.footer p:last-child{
    font-size: 0.5em;
}

.footer a{
    color: white;
    text-decoration: none;
}