.header{
    display: flex;
    background-color: #282828;
    background-image: url("../../Materials/Header.png");
    background-size: cover;
    width: 100%;
    height: 90vh;

    -webkit-box-pack: center;
    -moz-box-pack: center;
    box-pack: center;
    justify-content: center;

    -webkit-box-align: center;
    -moz-box-align: center;
    box-align: center;
    align-items: center;
}

#header-logo{
    width: 30%;
    background-color: white;
    text-align: center;
}

#header-logo > p {
    font-size: 2em;
    margin: 18px;
    text-shadow: 2px 2px #d3d3d3;
    font-weight: 800;
}

#lang-change{
    font-family: 'Roboto Slab', 'Patua One', serif;
    font-size: 0.9em;
    position:absolute;
    top:0;
    right: 30px;
    transition:font-size 3s;
}

#lang-change p{
    margin: 0px;
    padding-top:4px;
}

#lang-change span:hover{
    font-size: 1.02em;
}

#polski{
    font-weight: 600;
}

@media only screen and (max-width: 796px){
    #header-logo{
        width: 50%;
    }

    #lang-change{
        right: 15px;
    }

    .header{
    height: 95vh;
    }

}

@media only screen and (max-width: 480px){
    #header-logo{
        width: 80%;
    }

}