.contact{
    background-color: #282828;
    padding: 60px 100px;
    width: 100%-200px;
    color: white;
    display: flex;
    flex-direction: column;
    background-image: url('../../Materials/neural-network.png');
    background-position: 105% 10%;
    background-repeat: no-repeat;
}

.contact h1{
    line-height: 1.25em;
    text-decoration: underline #d3d3d3;
    margin-bottom: 24px;
}

.contact p{
    line-height: 1.4em;
    font-size: 1.1em;
}

.contact-info{
    padding: 10px 40px;
}

.contact-one{
    display: flex;
    align-items: center;
}

.contact-one img{
    height: 32px;
    width: 32px;
}

.contact-one p{
    display: inline-block;
    margin-left: 18px;
    font-size: 0.95em;
}

@media only screen and (max-width: 980px){
    .contact{
        background-position: 170% 10%;
    }

    .contact-info{
        padding: 0px;
    }
}

@media only screen and (max-width: 798px){
    .contact{
        padding: 30px 60px;
        width: 100%-120px;
        background-image: none;
        text-align: center;
    }

    .contact-one{
        justify-content: center;
    }

    .contact-one p{
        display: inline-block;
        margin-left: 18px;
        font-size: 0.85em;
    }
}