.subsidy{
    padding: 60px 100px;
    width: 100%-200px;
}

.subsidy-text{
    text-align: center;
}

.subsidy h1{
    line-height: 1.25em;
    text-decoration: underline #282828;
    margin-bottom: 24px;
}

.subsidy p{
    font-size: 0.85em;
}

.subsidy img{
    width: 80%;
}

@media only screen and (max-width: 980px){
    .subsidy img{
        width: 100%;
    }
}


@media only screen and (max-width: 798px){
    .subsidy{
        padding: 30px 60px;
        width: 100%-120px;
        justify-content: center;
        text-align: center;
    }
}