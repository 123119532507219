.navbar{
    width: 100%;
    min-height: 10vh;
}

.navbar-content{
    text-align: center;
    padding: 50px 0px;
    list-style-type: none;
    justify-content: space-between;
}

.navbar-content ul{
    padding: 0px;
}

.navbar-content li {
    display: inline-block;
    font-size: 1.8em;
    width: 24%;
    border-right: 4px solid #282828;
}

.navbar-content li:last-child {
    border: 0px;
}

.navbar-content li a{
    text-decoration: none;
    transition:font-size 1s;
    color: black;
    text-shadow: 1px 1px #d3d3d3;
    font-weight: 600;
}

.navbar-content li a:hover{
    font-size: 1.05em;
}

@media only screen and (max-width: 796px){
    .navbar-content li {
        font-size: 1.5em;
        border-right: 2px solid #282828;
    }
}

@media only screen and (max-width: 670px){
    .navbar-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 0px;
    }

    .navbar-content ul{
        width: 80%;
    }

    .navbar-content li {
        display: block;
        width: 100%;
        border-right: 0px;
        border-bottom: 1px solid #282828;
        padding: 4px 0px;
        font-size: 1.7em;
    }

}
