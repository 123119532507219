.about-me{
    display: flex;
    background-color: #282828;
    padding: 60px 100px;
    width: 100%-200px;
    color: white;
    justify-content: center;
    align-items: center;
}

.about-me h1{
    line-height: 1.25em;
    text-decoration: underline #d3d3d3;
    margin-bottom: 24px;
}

.about-me p{
    font-family: 'Roboto Slab', 'Patua One', serif;
    line-height: 1.4em;
}

.about-text{
    width: 70%;
    margin-right: 8%;
}

.about-img img{
    width: 350px;
}

@media only screen and (max-width: 980px){
    .about-img img{
        width: 270px;
    }

    .about-me p{
        font-family: 'Roboto Slab', 'Patua One', serif;
        line-height: 1.2em;
    }
}

@media only screen and (max-width: 798px){
    .about-me{
        padding: 30px 60px;
        width: 100%-120px;
        flex-direction: column;
    }

    .about-text{
        width: 100%;
        margin-right: 0px;
        text-align: center;
    }

    .about-img{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 8px;
    }
}