@import url(https://fonts.googleapis.com/css2?family=Patua+One&family=Roboto+Slab:wght@380&display=swap);
body{
    padding: 50px 30px;
    margin: 0px;
    font-family: 'Roboto Slab', 'Lucida Sans', sans-serif;
    color: #282828;
}

.lang-ang{
    display: none;
}

@media only screen and (max-width: 796px){
    body{
        padding: 30px 15px;
    }
}
.header{
    display: flex;
    background-color: #282828;
    background-image: url(../../static/media/Header.357cebfc.png);
    background-size: cover;
    width: 100%;
    height: 90vh;
    box-pack: center;
    justify-content: center;
    box-align: center;
    align-items: center;
}

#header-logo{
    width: 30%;
    background-color: white;
    text-align: center;
}

#header-logo > p {
    font-size: 2em;
    margin: 18px;
    text-shadow: 2px 2px #d3d3d3;
    font-weight: 800;
}

#lang-change{
    font-family: 'Roboto Slab', 'Patua One', serif;
    font-size: 0.9em;
    position:absolute;
    top:0;
    right: 30px;
    transition:font-size 3s;
}

#lang-change p{
    margin: 0px;
    padding-top:4px;
}

#lang-change span:hover{
    font-size: 1.02em;
}

#polski{
    font-weight: 600;
}

@media only screen and (max-width: 796px){
    #header-logo{
        width: 50%;
    }

    #lang-change{
        right: 15px;
    }

    .header{
    height: 95vh;
    }

}

@media only screen and (max-width: 480px){
    #header-logo{
        width: 80%;
    }

}
.navbar{
    width: 100%;
    min-height: 10vh;
}

.navbar-content{
    text-align: center;
    padding: 50px 0px;
    list-style-type: none;
    justify-content: space-between;
}

.navbar-content ul{
    padding: 0px;
}

.navbar-content li {
    display: inline-block;
    font-size: 1.8em;
    width: 24%;
    border-right: 4px solid #282828;
}

.navbar-content li:last-child {
    border: 0px;
}

.navbar-content li a{
    text-decoration: none;
    transition:font-size 1s;
    color: black;
    text-shadow: 1px 1px #d3d3d3;
    font-weight: 600;
}

.navbar-content li a:hover{
    font-size: 1.05em;
}

@media only screen and (max-width: 796px){
    .navbar-content li {
        font-size: 1.5em;
        border-right: 2px solid #282828;
    }
}

@media only screen and (max-width: 670px){
    .navbar-content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 0px;
    }

    .navbar-content ul{
        width: 80%;
    }

    .navbar-content li {
        display: block;
        width: 100%;
        border-right: 0px;
        border-bottom: 1px solid #282828;
        padding: 4px 0px;
        font-size: 1.7em;
    }

}

.about-me{
    display: flex;
    background-color: #282828;
    padding: 60px 100px;
    width: 100%-200px;
    color: white;
    justify-content: center;
    align-items: center;
}

.about-me h1{
    line-height: 1.25em;
    -webkit-text-decoration: underline #d3d3d3;
            text-decoration: underline #d3d3d3;
    margin-bottom: 24px;
}

.about-me p{
    font-family: 'Roboto Slab', 'Patua One', serif;
    line-height: 1.4em;
}

.about-text{
    width: 70%;
    margin-right: 8%;
}

.about-img img{
    width: 350px;
}

@media only screen and (max-width: 980px){
    .about-img img{
        width: 270px;
    }

    .about-me p{
        font-family: 'Roboto Slab', 'Patua One', serif;
        line-height: 1.2em;
    }
}

@media only screen and (max-width: 798px){
    .about-me{
        padding: 30px 60px;
        width: 100%-120px;
        flex-direction: column;
    }

    .about-text{
        width: 100%;
        margin-right: 0px;
        text-align: center;
    }

    .about-img{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 8px;
    }
}
.services{
    padding: 60px 100px;
    width: 100%-200px;
}

.services h1{
    line-height: 1.25em;
    -webkit-text-decoration: underline #282828;
            text-decoration: underline #282828;
    margin-bottom: 24px;
}

.services p{
    line-height: 1.4em;
}

.services-all{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.services-column{
    width: 47%;
}

.service-one{
    display: flex;
    padding: 28px 0px;
    align-items: center;
}

.service-one img{
    width: 120px;
    height: 120px;
    margin-right: 32px;
}

@media only screen and (max-width: 980px){
    .services-all{
        flex-direction: column;
    }
    .services p{
        line-height: 1.2em;
    }

    .service-one img{
        width: 100px;
        height: 100px;
        margin-right: 24px;
    }

    .services-column{
        width: 100%;
    }
}

@media only screen and (max-width: 798px){
    .services{
        padding: 30px 60px;
        width: 100%-120px;
        text-align: center;
        justify-content: center;
    }

    .services-all{
        flex-direction: column;
    }

    .services-column{
        text-align: left;
    }
}
.contact{
    background-color: #282828;
    padding: 60px 100px;
    width: 100%-200px;
    color: white;
    display: flex;
    flex-direction: column;
    background-image: url(../../static/media/neural-network.99ebf301.png);
    background-position: 105% 10%;
    background-repeat: no-repeat;
}

.contact h1{
    line-height: 1.25em;
    -webkit-text-decoration: underline #d3d3d3;
            text-decoration: underline #d3d3d3;
    margin-bottom: 24px;
}

.contact p{
    line-height: 1.4em;
    font-size: 1.1em;
}

.contact-info{
    padding: 10px 40px;
}

.contact-one{
    display: flex;
    align-items: center;
}

.contact-one img{
    height: 32px;
    width: 32px;
}

.contact-one p{
    display: inline-block;
    margin-left: 18px;
    font-size: 0.95em;
}

@media only screen and (max-width: 980px){
    .contact{
        background-position: 170% 10%;
    }

    .contact-info{
        padding: 0px;
    }
}

@media only screen and (max-width: 798px){
    .contact{
        padding: 30px 60px;
        width: 100%-120px;
        background-image: none;
        text-align: center;
    }

    .contact-one{
        justify-content: center;
    }

    .contact-one p{
        display: inline-block;
        margin-left: 18px;
        font-size: 0.85em;
    }
}
.subsidy{
    padding: 60px 100px;
    width: 100%-200px;
}

.subsidy-text{
    text-align: center;
}

.subsidy h1{
    line-height: 1.25em;
    -webkit-text-decoration: underline #282828;
            text-decoration: underline #282828;
    margin-bottom: 24px;
}

.subsidy p{
    font-size: 0.85em;
}

.subsidy img{
    width: 80%;
}

@media only screen and (max-width: 980px){
    .subsidy img{
        width: 100%;
    }
}


@media only screen and (max-width: 798px){
    .subsidy{
        padding: 30px 60px;
        width: 100%-120px;
        justify-content: center;
        text-align: center;
    }
}
.footer{
    padding: 10px 100px;
    width: 100%-200px;
    background-color: #282828;
    color: white;
    text-align: center;
}

.footer p{
    margin:0px;
}

.footer p:first-child{
    font-size: 0.7em;
    margin-bottom: 8px;
}

.footer p:last-child{
    font-size: 0.5em;
}

.footer a{
    color: white;
    text-decoration: none;
}
